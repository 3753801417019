import { createStore } from 'vuex'
import { checkCurrentUser } from '@/helpers/auth.helper';

export default createStore({
  state: {
    loading: false,
    logged_user:{},
  },

  getters: {
    getLoggedUser(state) {
      const logged_user = state.logged_user || {};
      if (!logged_user || !logged_user?.id) {
        state.logged_user = checkCurrentUser() || {};
      }
      return state.logged_user;
    },
  },
  mutations: {
    setLoggedUser(state, val) {
      if (val) {
        localStorage.setItem('lnt_user', JSON.stringify(val));
      }
      state.logged_user = val;
    },
    showLoader(state) {
      state.loading = true;
    },
    hideLoader(state) {
      state.loading = false;
    },
  },
  actions: {
  },
  modules: {
  }
})
