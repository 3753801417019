export const checkCurrentUser = () => {
  let user;
  try {
    user = JSON.parse(localStorage.getItem('lnt_user'));
  } catch (e) {
    console.log(e);
  }
  if (!user?.id) {
    delete localStorage.lnt_user;
    delete localStorage.lnt_jwt;
  }
  return user;
};