<template>
  <div class='wrapper'>
    <Header />
    <div class='content'>
        <slot></slot>
    </div>
  </div>
</template>

<script>


import Header from "@/components/Header.vue";

export default {
  name: "MainLayoutAdmin",
  components: {
    Header
  }

};
</script>

<style lang='scss' scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #EEF1F6;
}
.content{
  padding: 40px;
}
@media (max-width: 992px) {
}
</style>